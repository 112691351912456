<template>
  <div class="content">
    <div class="container text-center" style="padding-top: 40px">
      <router-link to="/home" class="text-decoration-none text-primary font-weight-bold">
        <img src="../assets/brasaostomasdeaquino.jpg" style="width: 90px" class="">
      </router-link>
      <div style="margin-top: 100px">
        <h3 class="font-weight-bold">Página não encontrada</h3>
        <p>
          Essa página não existe ou foi removida.
        </p>
        <router-link to="/home" class="btn btn-outline-secondary">Voltar para Home</router-link>
      </div>
    </div>
    <botton-bar/>
  </div>
</template>

<script>
  import BottonBar from "../components/BottonBar";

  export default {
    name: 'P404',
    components: {BottonBar}
  }
</script>
<style scoped>
  .content {
    background: url("../assets/wave-light.png");
    background-size: 100% 195px;
    background-repeat: no-repeat;
    background-position: top;
  }
</style>
