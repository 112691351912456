<template>
  <div>
    <top-bar title="Ouvidoria"/>
    <div class="content">
      <div class="container">
        <div class="d-flex mb-3">
          <input type="search" class="form-control mr-2" placeholder="Pesquisar ouvidoria" v-model="term"
                 @search="pesquisar">
          <button class="btn btn-primary" @click="pesquisar" style="width: 90px"><i class="fa fa-search"></i></button>
        </div>
        <card-info class="mb-3" icon="information" title="Queremos te ouvir" v-if="!pesquisou">
          <div class="small">Envie suas sugestões, dúvidas ou reclamações</div>
        </card-info>
        <div class="row mb-4" v-if="pesquisou">
          <div class="col-md-6 mb-2" v-for="o in searchResult">
            <lista :nome="o.nome_servico" icone="box" :url="'/ouvidoria/' + o.id_servico"/>
          </div>
          <div class="col-md-12 text-center mt-4" v-if="!searchResult.length">
            <i class="fa fa-frown-o fa-2x text-secondary"></i>
            <p class="mt-2">Nenhum resultado foi encontrado</p>
          </div>
        </div>
        <div class="row mb-4" v-else>
          <div class="col-md-6 mb-2" v-for="o in ouvidoria">
            <lista :nome="o.nome_servico" icone="box" :url="'/ouvidoria/' + o.id_servico"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import TopBar from "../components/TopBar";
  import Lista from "../components/Lista";
  import Loading from "../components/Loading";
  import CardInfo from "../components/CardInfo";

  export default {
    name: 'Home',
    components: {CardInfo, Loading, Lista, TopBar},

    data() {
      return {
        term: '',
        pesquisou: false,
        searchResult: []
      }
    },

    methods: {
      pesquisar() {
        if (this.term.trim().length) {
          this.$search(this.term, this.ouvidoria, {
            shouldSort: true,
              threshold: 0.2,
              location: 0,
              distance: 100,
              maxPatternLength: 32,
              minMatchCharLength: 1,
              keys: ["nome_servico"]
          }).then(results => {
            this.searchResult = results;
            this.$nextTick(() => {
              this.pesquisou = true;
            })
          });

        } else {
          this.pesquisou = false;
          this.searchResult = [];
        }
      },
    },

    mounted() {
      window.scrollTo(0,0);
    },

    computed: {
      ouvidoria() {
        return this.$store.state.dados.ouvidoria;
      }
    }
  }
</script>

