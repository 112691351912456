<template>
  <div>
    <loading v-if="load" fullscrean="true"/>
    <div v-show="!load">
      <top-bar title="Informativos" url="/home"/>
      <div class="content">
        <div class="container">
          <card-info class="mb-3" icon="information" :title="prefeitura.nome_prefeitura">
            <div class="small">Mantenha-se informado com os principais acontecimentos da nossa cidade</div>
          </card-info>
          <div class="mt-4 mb-4 mx-auto" style="max-width: 400px">
            <select class="form-control" @change="buscarArtigos(true)" v-model="pesquisa.id_departamento">
              <option value="">Todos Departamentos</option>
              <option :value="d.id_departamento" v-for="d in departamentos">{{ d.nome_departamento }}</option>
            </select>
          </div>
          <c-artigos :artigos="artigos"/>
          <div class="my-4 text-center" v-if="artigos.length === maximo">
            <button class="btn btn-outline-primary btn-sm" @click="buscarArtigos()" :disabled="btnLoad">
              {{ btnLoad ? 'Carregando..' : 'Ver Mais'}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import TopBar from "../components/TopBar";
  import Lista from "../components/Lista";
  import Loading from "../components/Loading";
  import CardInfo from "../components/CardInfo";
  import CArtigos from "@/components/CArtigos";

  export default {
    name: 'Home',
    components: {CArtigos, CardInfo, Loading, Lista, TopBar},

    data() {
      return {
        load: true,
        btnLoad: true,
        pesquisa: {
          id_departamento: '',
          pagina: 0
        },
        departamentos: [],
        artigos: [],
        maximo: 1
      }
    },

    methods: {
      buscarDepartamentos() {
        this.$http.get('app/departamentos/' + this.prefeituraToken)
            .then(response => {
              this.departamentos = response.data;

            }, res => {
              console.log(res);
              this.load = false;
            });
      },

      buscarArtigos(load) {
        if (load) {
          this.artigos = [];
          this.load = true;
          this.pesquisa.pagina = 0;

        } else {
          this.btnLoad =  true;
          this.pesquisa.pagina++;
        }

        this.$http.get('app/artigos/' + this.prefeituraToken, { params: this.pesquisa })
          .then(response => {
            if (response.data) {
              this.artigos = this.artigos.concat(response.data);
              this.maximo = 12 * (this.pesquisa.pagina + 1);
            }

            this.load = false;
            this.btnLoad = false;

          }, res => {
            console.log(res);
            this.load = false;
            this.btnLoad = false;
        });
      }
    },

    mounted() {
      window.scrollTo(0,0);
      this.buscarDepartamentos();
      this.buscarArtigos(true);
    },

    computed: {
      prefeitura() {
        return this.$store.state.dados.prefeitura
      }
    }
  }
</script>
<style scoped>
</style>