<template>
  <div class="content">
    <top-bar :title="servico.nome_servico"/>
    <loading :fullscrean="true" v-if="load"/>
    <div v-else>
      <form @submit.prevent="validarSolicitacao">
        <div class="container">
          <div>
            <h6 class="font-weight-bold mb-0">Descrição</h6>
            <p class="mb-4">{{servico.descricao}}</p>
          </div>
          <div class="card shadow-sm mb-3">
            <div class="card-body">
              <div v-if="token">
                <h4 class="h6 font-weight-bold m-0">Solicitante *</h4>
                <p class="small m-0">{{user.nome_cidadao}}</p>
              </div>
              <div v-else>
                <h4 class="h6 font-weight-bold m-0">Faça seu cadastro</h4>
                <p class="small mb-2">Para enviar um serviço você precisa ser cadastrado</p>
                <button class="btn btn-secondary btn-block">Cadastrar</button>
              </div>
            </div>
          </div>
          <div v-show="token">
            <div class="card shadow-sm mb-3">
              <div class="card-body">
                <h4 class="h6 font-weight-bold m-0">Qual o Local ? <span v-if="servico.local === '1'">*</span></h4>
                <div class="small">
                  <p class="mb-2" v-if="!dados.logradouro">Informe o local da sua solicitação</p>
                  <p class="mb-2" v-else>
                    {{dados.logradouro}}
                    <span v-if="dados.numero">, {{dados.numero}}</span>
                    <span v-if="dados.bairro">, {{dados.bairro}}</span>
                    <span class="d-block" v-if="dados.complemento">Referência: {{dados.complemento}}</span>
                  </p>
                </div>
                <button class="btn btn-secondary btn-block" @click="openModal(1)" type="button">
                  {{dados.logradouro ? 'Alterar' : 'Cadastrar'}} Endereço
                </button>
              </div>
            </div>
            <div class="card shadow-sm mb-3">
              <div class="card-body">
                <h4 class="h6 font-weight-bold m-0">Imagens <span v-if="servico.arquivo === '1'">*</span></h4>
                <p class="small mb-0">Máximo 4 arquivos. Até 2 mb</p>
                <div class="mb-3 d-flex flex-wrap" v-if="dados.arquivos.length">
                  <a href="javascript:" v-for="(img, index) in dados.arquivos" class="file" @click="removerImg(index)">
                    <img :src="img.url_imagem" alt="Preview" class="shadow-sm rounded-sm">
                  </a>
                </div>
                <label class="btn btn-block btn-secondary mt-2" :class="{'disabled': btnUpload}" v-show="dados.arquivos.length < 4">
                  <span v-if="btnUpload"><i class="fa fa-spinner fa-spin"></i> <b class="ml-2">Enviando</b></span>
                  <span v-else>Enviar Imagens</span>
                  <input type="file" class="d-none" accept="image/*" capture="environment" @change="enviarImagem($event)" :disabled="btnUpload || dados.arquivos.length > 3">
                </label>
              </div>
            </div>
            <div class="mb-3">
              <label class="mb-0 font-weight-bold" for="observacao">Mensagem</label>
              <textarea id="observacao" class="form-control" placeholder="Descreva aqui os detalhes da sua solicitação" rows="4" style="resize: none" v-model="dados.mensagem" @change="salvarSolicitacao"></textarea>
            </div>
            <div class="card shadow-sm mb-2 pointer" @click="() => {dados.anonimo = !dados.anonimo; salvarSolicitacao()}">
              <div class="card-body">
                <div class="d-flex align-items-center">
                  <input type="checkbox" id="anonimo" title="Anonimo" value="1" v-model="dados.anonimo" @change="salvarSolicitacao">
                  <div class="pl-3 m-0">
                    <h6 class="text-primary font-weight-bold m-0">Quero ficar anônimo</h6>
                    <div class="small">Sua identidade não será revelada</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="small">* Campos Obrigatórios</div>
          </div>
        </div>
        <div class="btn-bottom fixed-bottom">
          <div class="container">
            <button class="btn btn-primary btn-block py-2" :disabled="btnLoad">
              <span>Enviar Solicitação</span>
            </button>
          </div>
        </div>
      </form>
    </div>

    <modal ref="modalEndereco" @voltar="fecharModalEndereco" title="Cadastrar Local" :overflow="true">
      <div v-if="modal === 1">
        <form @submit.prevent="salvarEndereco">
          <div class="container container-endereco">
            <div class="mb-3 pointer" @click="$swal('Aqui você poderá informar o local aproximado da sua solicitação, o número serve apenas de base, não é obrigatório ser informado.')">
              <card-info title="Dúvidas sobre a localização?" icon-pos="fa-info-circle">
                <div class="small">Clique aqui para saber mais</div>
              </card-info>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group mb-2">
                  <label for="endereco" class="mb-0">Logradouro *</label>
                  <input id="endereco" type="text" class="form-control" placeholder="Ex: Rua Joaquim de Oliveira" maxlength="256" v-model="endereco.logradouro" required>
                  <div class="text-muted small">{{prefeitura.nome_cidade}} - {{prefeitura.sigla_estado}}</div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group mb-2">
                  <label for="numero" class="mb-0">Número</label>
                  <input id="numero" type="text" class="form-control" placeholder="Ex: 1170" maxlength="11" v-model="endereco.numero">
                </div>
              </div>
              <div class="col-md-8">
                <div class="form-group mb-2">
                  <label for="bairro" class="mb-0">Bairro *</label>
                  <input id="bairro" type="text" class="form-control" placeholder="Ex: Centro" maxlength="100" v-model="endereco.bairro" required>
                </div>
              </div>
            </div>
            <div class="form-group mb-3">
              <label for="complemento" class="mb-0">Descrever Local *<span class="pl-1 text-muted small">({{endereco.complemento ? endereco.complemento.length : 0}} de 100)</span></label>
              <textarea id="complemento" class="form-control" placeholder="Ponto de Referência (Ex: Em frente ao hospital) / Complemento / Bloco / Apto" style="resize: none" v-model="endereco.complemento" maxlength="100" required></textarea>
            </div>
          </div>
          <div class="btn-bottom btn-avancar">
            <div class="container">
              <button class="btn btn-secondary btn-block py-2">Salvar</button>
            </div>
          </div>
        </form>
      </div>
    </modal>

    <modal-bottom :opened="confirmar">
      <div class="container">
        <h6 class='font-weight-bold my-3 text-center'>Podemos confirmar sua solicitação?</h6>
        <div class="mb-4">
          <div>
            <b>Local:</b> {{dados.logradouro}}<span v-if="dados.numero">, {{dados.numero}}</span>
            <span v-if="dados.bairro">, {{dados.bairro}}</span>
            <span class="d-block" v-if="dados.complemento">Referência: {{dados.complemento}}</span>
          </div>
          <div v-if="user.telefone"><b>Telefone contato:</b> {{user.telefone | phone}}</div>
          <div><b>Serviço:</b> {{servico.nome_servico}}</div>
          <div v-if="dados.assunto"><b>Assunto:</b> {{dados.assunto}}</div>
          <div v-if="dados.anonimo">Sua solicitação será enviada anônimamente</div>
        </div>
        <div>
          <button class="btn btn-primary btn-block rounded-sm p-2 mb-3" @click="enviarSolicitacao" :disabled="btnLoad">
            <span v-show="!btnLoad"><b>Confirmar</b></span>
            <span v-show="btnLoad"><i class="fa fa-spinner fa-spin"></i> <b class="pl-2">Enviando sua solicitação</b></span>
          </button>
          <button type="button" class="btn btn-block rounded-sm p-3 mb-2" @click="confirmar = false" v-show="!btnLoad">
            <span class="font-weight-bold text-primary">Fechar</span>
          </button>
        </div>
      </div>
    </modal-bottom>

  </div>
</template>

<script>
  import TopBar from "../components/TopBar";
  import Lista from "../components/Lista";
  import CardInfo from "../components/CardInfo";
  import Loading from "../components/Loading";
  import Modal from "../components/Modal";
  import ModalBottom from "../components/ModalBottom";
  import * as imageConversion from 'image-conversion';
  import heic2any from "heic2any";

  export default {
    name: 'Ouvidoria',
    components: {ModalBottom, Modal, Loading, CardInfo, Lista, TopBar},
    data() {
      return {
        step: 1,
        load: true,
        token: localStorage.getItem('token'),

        confirmar: false,
        btnLoad: false,
        btnUpload: false,

        servico:{
          nome_servico: 'Serviços',
          descricao: '',
          arquivo: '',
          tipo_solicitacao: '',
          local: '',
        },

        dados: {
          tipo_solicitacao: '1',
          arquivos: [],
          logradouro: '',
          numero: '',
          complemento: '',
          nome_cidade: '',
          sigla_estado: '',
          bairro: '',
          cep: '',
          mensagem: '',
          anonimo: false
        },

        modal: 2,
        endereco: {
          logradouro: '',
          numero: '',
          bairro: '',
          complemento: ''
        }
      }
    },

    methods: {
      getDados() {
        this.$http.get('app/servico/' + this.prefeituraToken + '/' + this.$route.params.id)
          .then(response => {
            this.servico = response.data;
            this.dados.id_servico = this.servico.id_servico;
            this.load = false;

            if (sessionStorage.getItem('solicitacao')) {
              const dados = JSON.parse(sessionStorage.getItem('solicitacao'));

              if (dados.id_servico === this.dados.id_servico) {
                this.dados = dados;

              } else {
                sessionStorage.clear();
              }
            }

          }, res => {
            console.log(res);
            this.load = false;
            let msg = res.data && res.data.msg ? res.data.msg : 'Erro temporário';
            this.$swal(msg)
          });
      },

      salvarSolicitacao() {
        sessionStorage.setItem('solicitacao', JSON.stringify(this.dados));
      },

      validarSolicitacao() {
        if (this.btnLoad) {
          return false;
        }

        if (this.btnUpload) {
          this.$swal("Aguarde o upload das imagens");
          return false;
        }

        if (this.servico.tipo_solicitacao === '1' && !this.token) {
          this.salvarSolicitacao();
          this.$router.push('/perfil/dados');
          this.$swal("Para continuar por favor faça seu login ou cadastro");
          return false;
        }

        if (this.servico.local === '1' && this.dados.logradouro.trim() === '') {
          this.$swal("Por favor inclua o local da sua solicitação").then(() => {
            this.openModal(1);
          });

          return false;
        }

        if (this.token && !this.dados.anonimo && !this.user.telefone) {
          this.$swal("Informe seu telefone caso um servidor precise entrar em contato").then((r) => {
            if (r) this.$helpers.focusElement('telefone');
          });
          this.$router.push('/perfil/dados/telefone');
          return false;
        }

        if (this.servico.arquivo === '1' && !this.dados.arquivos.length) {
          this.$swal("Você precisa incluir pelo menos uma imagem na sua solicitação");
          return false;
        }

        this.confirmar = true;
        return true;
      },

      enviarSolicitacao() {
        if (!this.validarSolicitacao()) {
          return;
        }

        if (this.confirmar) {
          this.btnLoad = true;

          if (localStorage.getItem("token")) {
            this.dados.token = localStorage.getItem("token");
          }

          this.$http.post('app/solicitacao/' + this.prefeituraToken, this.dados)
            .then(response => {
              const data = response.data;

              if (data && data.protocolo) {
                sessionStorage.clear();
                this.$router.push('/confirmacao/' + data.protocolo)
              }

              this.btnLoad = false;

            }, res => {
              console.log(res);
              this.btnLoad = false;
              let msg = res.data && res.data.msg ? res.data.msg : 'Erro temporário';
              this.$swal(msg)
            });
        }
      },

      salvarEndereco() {
        this.dados.logradouro = this.endereco.logradouro;
        this.dados.numero = this.endereco.numero;
        this.dados.bairro = this.endereco.bairro;
        this.dados.complemento = this.endereco.complemento;

        this.salvarSolicitacao();
        this.fecharModalEndereco();
      },

      enviarImagem(e) {
        if (e && !this.btnUpload && this.dados.arquivos.length < 4) {
          const img = e.target.files[0];

          if (img) {
            this.btnUpload = true;

            if (img.size > 5000000) {
              this.btnUpload = false;
              this.$swal("O tamanho máximo permitido de uma imagem é de 5MB!");
              return;
            }

            if (img.name.endsWith('.HEIC')) {
              this.heycImage(img);

            } else {

              const aloowed = ['image/jpeg','image/png','image/jpg',];

              if (!aloowed.includes(img.type)) {
                this.btnUpload = false;
                this.$swal("Formato não aceito! É permitido apenas imagens JPG, PNG ou JPEG");
                return;
              }

              this.compressImage(img, img.name);
            }
          }
        }
      },

      heycImage(img) {
        this.btnUpload = true;
        heic2any({ blob: img, toType: "image/jpeg", quality: 0.5}).then((res) => {
          this.compressImage(res, "image.jpeg");

        }).catch(() => {
          this.btnLoad = false;
        });
      },

      compressImage(img, name) {
        this.btnUpload = true;

        imageConversion.compress(img, { width: 512, quality: 0.8 }).then(async file => {
          const data = new FormData();
          data.append('file', file, name);

          this.$http.post('app/upload/' + this.prefeituraToken, data)
            .then(response => {
              if (response && response.data && response.data.url_imagem) {
                this.$swal("Imagem anexada com sucesso!");

                this.dados.arquivos.push({
                  id_arquivo: response.data.id_arquivo,
                  url_imagem: response.data.url_imagem
                });

                this.salvarSolicitacao();

              } else  {
                this.$swal("Não foi possível anexar esta imagem");
              }

              this.btnUpload = false;

            }, res => {
              console.log(res);
              this.btnUpload = false;
              let msg = res.data && res.data.msg ? res.data.msg : 'Erro temporário';
              this.$swal(msg)
            });


        }, res => {
          this.$swal("Não foi possível anexar esta imagem");
          this.btnUpload = false;
        });
      },

      removerImg(index) {
        if (!this.btnUpload) {
          this.$swal({
            text: "Deseja remover esta imagem da sua solicitação?",
            buttons: ["NÃO", "SIM"]
          }).then((res) => {
            if (res) {
              this.dados.arquivos.splice(index, 1);
              this.salvarSolicitacao();
            }
          });
        }
      },

      openModal(modal) {
        this.modal = modal;
        this.$emit('openModal');

        if (modal === 1) {
          this.endereco.logradouro = this.dados.logradouro;
          this.endereco.numero = this.dados.numero;
          this.endereco.bairro = this.dados.bairro;
          this.endereco.complemento = this.dados.complemento;

          this.$helpers.focusElement('endereco');
        }
      },

      fecharModalEndereco() {
        this.$emit('closeModal');
      }
    },

    mounted() {
      this.getDados();
    },

    computed: {
      user() {
        return this.$store.state.user
      },

      prefeitura() {
        return this.$store.state.dados.prefeitura
      }
    }
  }
</script>
<style scoped>
  .file {
    /*margin-bottom: 24px;*/
    display: block;
    position: relative;
  }

  .file:before {
    content: ' ';
    position: absolute;
    z-index: 1;
    right: 0;
    top: 0;
    width: 24px;
    height: 24px;
    background: url("../assets/icones/remove.png") no-repeat;
    background-size: 100%;
  }

  .file img {
    width: 160px;
    height: 160px;
    object-fit: cover;
    margin: 12px 6px 6px;
  }

  .btn-avancar {
    position:fixed;
    right:0;
    bottom:0;
    left:0;
    z-index:1030;
    background-color: #fff;
  }

  .container-endereco {
    padding-top: 16px;
    padding-bottom: 92px;
  }

  .content {
    padding-bottom: 102px;
  }

  @media (min-width: 600px) {
    .btn-avancar {
      position:static;
    }

    .container-endereco {
      overflow: auto;
      height: calc(80vh - 130px);
      padding-bottom: 0;
    }
  }
</style>
