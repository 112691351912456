<template>
  <div class="content">
    <top-bar :title="ouvidoria.nome_servico"/>
    <loading :fullscrean="true" v-if="load"/>
    <div class="container" v-else>
      <form @submit.prevent="validarSolicitacao">
        <div v-if="ouvidoria.descricao">
          <h6 class="font-weight-bold mb-0">{{ouvidoria.nome_servico}}</h6>
          <p class="mb-4">{{ouvidoria.descricao}}</p>
        </div>

        <div class="row no-gutters mb-4">
          <div class="col-4 px-1">
            <div class="card text-center pointer text-muted card-ouvidoria" :class="{'border-primary text-primary': dados.tipo_ouvidoria === '1'}" @click="alterarTipo('1')">
              <icon name="information" size="28"/>
              <div class="font-weight-bold mt-2 small">Dúvidas</div>
            </div>
          </div>
          <div class="col-4 px-1">
            <div class="card text-center pointer text-muted card-ouvidoria" :class="{'border-primary text-primary': dados.tipo_ouvidoria === '2'}" @click="alterarTipo('2')">
              <icon name="bulb" size="28"/>
              <div class="font-weight-bold mt-2 small">Sugestões</div>
            </div>
          </div>
          <div class="col-4 px-1">
            <div class="card text-center pointer text-muted card-ouvidoria" :class="{'border-primary text-primary': dados.tipo_ouvidoria === '3'}" @click="alterarTipo('3')">
              <icon name="claims" size="28"/>
              <div class="font-weight-bold mt-2 small">Reclamação</div>
            </div>
          </div>
        </div>
        <div class="mb-3">
          <label class="font-weight-bold mb-0" for="assunto">Assunto *</label>
          <input type="text" id="assunto" class="form-control" placeholder="Informe o assunto" v-model="dados.assunto" required @change="salvarSolicitacao">
        </div>
        <div class="mb-3">
          <label class="mb-0 font-weight-bold" for="observacao">Mensagem *</label>
          <textarea id="observacao" class="form-control" placeholder="Descreva aqui sua mensagem" v-model="dados.mensagem" rows="4" style="resize: none" required @change="salvarSolicitacao"></textarea>
        </div>

        <div class="card shadow-sm mb-3">
          <div class="card-body">
            <div v-if="token">
              <h4 class="h6 font-weight-bold m-0">Solicitante {{dados.tipo_ouvidoria === '1' ? '*' : ''}}</h4>
              <p class="small m-0">{{user.nome_cidadao}}</p>
            </div>
            <div v-else>
              <h4 class="h6 font-weight-bold m-0">Faça seu cadastro</h4>
              <p class="small mb-2">Receba e acompanhe o retorno das suas sugestões ou reclamações.</p>
              <router-link to="/perfil/dados" class="btn btn-secondary btn-block">Cadastrar</router-link>
            </div>
          </div>
        </div>

        <div class="card shadow-sm mb-2 pointer" v-show="token" @click="() => {dados.anonimo = !dados.anonimo; salvarSolicitacao()}">
          <div class="card-body">
            <div class="d-flex align-items-center">
              <input type="checkbox" id="anonimo" title="Anonimo" value="1" v-model="dados.anonimo" @change="salvarSolicitacao">
              <div class="pl-3 m-0">
                <h6 class="text-primary font-weight-bold m-0">Quero ficar anônimo</h6>
                <div class="small">Sua identidade não será revelada</div>
              </div>
            </div>
          </div>
        </div>

        <div class="small">* Campos Obrigatórios</div>

        <div class="btn-bottom fixed-bottom">
          <div class="container">
            <button class="btn btn-primary btn-block py-2">Enviar Mensagem</button>
          </div>
        </div>
      </form>
    </div>

    <modal-bottom :opened="confirmar">
      <div class="container">
        <h6 class='font-weight-bold my-3 text-center'>Podemos confirmar sua solicitação?</h6>
        <div class="mb-4">
          <div><b>Tipo:</b> {{dados.tipo_ouvidoria | tipo}}</div>
          <div><b>Ouvidoria:</b> {{ouvidoria.nome_servico}}</div>
          <div v-if="dados.assunto"><b>Assunto:</b> {{dados.assunto}}</div>
          <div v-if="token"><b>Telefone contato:</b> {{user.telefone | phone}}</div>
          <div v-if="dados.anonimo">Sua mensagem será enviada anônimamente</div>
        </div>
        <div>
          <button class="btn btn-primary btn-block rounded-sm p-2 mb-3" @click="enviarSolicitacao" :disabled="btnLoad">
            <span v-show="!btnLoad"><b>Confirmar</b></span>
            <span v-show="btnLoad"><i class="fa fa-spinner fa-spin"></i> <b class="pl-2">Enviando sua solicitação</b></span>
          </button>
          <button type="button" class="btn btn-block rounded-sm p-3 mb-2" @click="confirmar = false" v-show="!btnLoad">
            <span class="font-weight-bold text-primary">Fechar</span>
          </button>
        </div>
      </div>
    </modal-bottom>

  </div>
</template>

<script>
  import TopBar from "../components/TopBar";
  import Lista from "../components/Lista";
  import CardInfo from "../components/CardInfo";
  import Icon from "../components/Icon";
  import Loading from "../components/Loading";
  import ModalBottom from "../components/ModalBottom";
  export default {
    name: 'Ouvidoria',
    components: {ModalBottom, Loading, Icon, CardInfo, Lista, TopBar},
    data() {
      return {
        step: 1,
        load: true,
        token: localStorage.getItem('token'),

        ouvidoria: {
          id_servico: '',
          nome_servico: 'Ouvidoria'
        },

        dados: {
          tipo_solicitacao: '2',
          id_servico: '',
          tipo_ouvidoria: '1',
          assunto: '',
          mensagem: '',
          anonimo: false
        },

        confirmar: false,
        btnLoad: false
      }
    },

    methods: {
      getDados() {
        this.$http.get('app/servico/' + this.prefeituraToken + '/' + this.$route.params.id)
          .then(response => {
            this.ouvidoria = response.data;
            this.dados.id_servico = this.ouvidoria.id_servico;
            this.load = false;

            if (sessionStorage.getItem('solicitacao')) {
              const dados = JSON.parse(sessionStorage.getItem('solicitacao'));

              if (dados.id_servico === this.dados.id_servico) {
                this.dados = dados;

              } else {
                sessionStorage.clear();
              }
            }

            this.salvarSolicitacao();

          }, res => {
            console.log(res);
            this.load = false;
          });
      },

      alterarTipo(tipo) {
        this.dados.tipo_ouvidoria = tipo;

        if (tipo === '1') {
          this.dados.anonimo = false;
        }

        this.salvarSolicitacao();
      },

      salvarSolicitacao() {
        sessionStorage.setItem('solicitacao', JSON.stringify(this.dados));
      },

      validarSolicitacao() {
        if (this.btnLoad) {
          return false;
        }

        if (this.dados.tipo_ouvidoria === '1' && !this.token) {
          this.salvarSolicitacao();
          this.$router.push('/perfil/dados');
          this.$swal("Para enviar uma dúvida você precisa fazer o login ou cadastro!");
          return false;
        }

        if (this.token && !this.dados.anonimo && !this.user.telefone) {
          this.$swal("Informe seu telefone caso um servidor precise entrar em contato").then((r) => {
            if (r) this.$helpers.focusElement('telefone');
          });
          this.$router.push('/perfil/dados/telefone');
          return false;
        }

        this.confirmar = true;
        return true;
      },

      enviarSolicitacao() {
        if (!this.validarSolicitacao() || !this.confirmar) {
          return;
        }

        if (localStorage.getItem("token")) {
          this.dados.token = localStorage.getItem("token");
        }

        this.btnLoad = true;
        this.$http.post('app/solicitacao/' + this.prefeituraToken, this.dados)
          .then(response => {
            const data = response.data;

            if (data && data.protocolo) {
              sessionStorage.clear();
              this.$router.push('/confirmacao/' + data.protocolo)
            }

            this.btnLoad = false;

          }, res => {
            console.log(res);
            this.btnLoad = false;
          });
      },
    },

    mounted() {
      this.getDados();
    },

    computed: {
      user() {
        return this.$store.state.user
      },

      prefeitura() {
        return this.$store.state.dados.prefeitura
      }
    }
  }
</script>
<style scoped>
  .card-ouvidoria {
    height: 95px;
    display: flex;
    justify-content: center;
  }

  .content {
    padding-bottom: 102px;
  }
</style>
