<template>
  <div id="app" :class="{'ios_platform': isIOS}">
    <loading v-if="load" :fullscrean="true" text="Por favor aguarde"/>
    <div v-else-if="dados.prefeitura">
      <router-view/>
    </div>
    <status-prefeitura v-else/>
  </div>
</template>

<script>
  import Loading from "./components/Loading";
  import StatusPrefeitura from "./components/StatusPrefeitura";

  export default {
    components: {StatusPrefeitura, Loading},
    data() {
      return {
        load: true,
        token: '',
        dados: {}
      }
    },

    methods: {
      getDados() {
        this.$http.get('app/dados/' + this.prefeituraToken)
          .then(response => {
            this.dados = response.data;
            this.$store.commit('setDadosApp', this.dados);
            this.token = localStorage.getItem("token");

            if (this.token) {
              this.autenticar();

            } else {

              if (localStorage.getItem('user')) {
                this.$store.state.user = JSON.parse(localStorage.getItem('user'));

                if (this.$route.name === 'Login') {
                  this.$router.push('/home');
                }
              }

              this.load = false;
            }

          }, res => {
            console.log(res);
            this.load = false;
          });
      },

      autenticar() {
        this.$http.post('app/login/' + this.prefeituraToken, {token: this.token})
          .then(res => {
            if (res && res.data.success) {
              this.user = res.data.dados;
              this.load = false;
              this.$store.commit('setDataUser', res.data);

              if (this.$route.name === 'Login') {
                this.$router.push('/home');
              }

              if (this.oneSignalIntegration) {
                OneSignal.push(() => {
                  OneSignal.isPushNotificationsEnabled(isEnabled => {
                    if (isEnabled) {
                      OneSignal.setExternalUserId(this.user.id_cidadao);
                      this.editarOnesignal(1)
                    } else {
                      this.editarOnesignal(0)
                    }
                  });
                });
              }
            }

            this.load = false;

          }, res => {
            this.load = false;
            let msg = res.data && res.data.msg ? res.data.msg : 'Erro temporário';
            this.$swal(msg).then(() => {
              if (res.status === 401 && this.$route.name !== 'HomeApp') {
                this.$router.push('/');
              }
            });

            if (res.status === 401) {
              this.$store.commit('logout');
            }
          });
      },

      editarOnesignal(habilitado) {
        const dados = { push_habilitado: habilitado };
        this.$http.post('app/onesignal/' + this.prefeituraToken + '/' + this.token, dados).then(res => {});
      },
    },

    mounted() {
      this.getDados();
    },

    created() {
      if (this.oneSignalIntegration) {
        OneSignal.push(() => {
          OneSignal.on("subscriptionChange", () => {
            if (this.token && this.user.id_cidadao) {
              OneSignal.setExternalUserId(this.user.id_cidadao);
              this.editarOnesignal(1);
            }
          });
        });
      }
    }
  }
</script>

<style>
</style>