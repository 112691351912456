<template>
  <div>
    <div class="fixed-top py-4 text-right">
      <div class="container">
        <router-link to="/home" class="pr-3">
          <i class="fa fa-close text-primary" style="font-size: 22px"></i>
        </router-link>
      </div>
    </div>
    <div class="d-flex align-items-center" style="height: 100vh">
      <div class="container text-center">
        <h4 class="font-weight-bold text-primary">Solicitação efetuada <br> com sucesso!</h4>
        <p class="mb-4 small">
          Recebemos sua solicitação e encaminhamos para o setor responsável.
          <span v-if="token">Você pode acompanhar o status no seu perfil.</span>
        </p>
        <div class="card shadow-sm mb-4">
          <div class="card-body text-muted">
            <h6 class="font-weight-bold mb-0">SOLICITAÇÃO</h6>
            <p class="m-0">{{this.$route.params.id}}</p>
          </div>
        </div>
        <div class="pb-4">
          <router-link to="/perfil/solicitacoes" class="btn btn-primary btn-block py-2 mb-3" v-if="token">ACOMPANHAR SOLICITAÇÃO</router-link>
          <router-link to="/home" class="btn btn-outline-primary btn-block py-2">VOLTAR PARA HOME</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import TopBar from "../components/TopBar";
  import Lista from "../components/Lista";
  import CardInfo from "../components/CardInfo";
  export default {
    name: 'Ouvidoria',
    components: {CardInfo, Lista, TopBar},
    data() {
      return {
        token: localStorage.getItem('token'),
        step: 1,
        servico: {
          nome: 'Limpeza de Terreno'
        }
      }
    },

    mounted() {
      // TODO validar solicitacao this.$route.params.id
    }
  }
</script>

<style scoped>
</style>
