<template>
  <div>
    <top-bar title="Meus Dados" />
    <loading v-show="load" :fullscrean="true" text="Carregando os dados"/>
    <div class="content" v-show="!load">
      <div class="container">
        <div class="mb-2 text-center" v-show="!editar">
          <div class="mb-3">
            Já é cadastrado? <router-link :to="url + '/perfil/login'" class="text-info">Fazer Login</router-link>
          </div>
          <div v-show="!isIOS">
            <login-facebook str="ENTRAR COM FACEBOOK" btncolor="btn-primary" @success="registrar"/>
          </div>
          <hr>
        </div>
        <form @submit.prevent="validate">
          <div v-if="!editarTelefone">
            <div class="form-group mb-2">
              <label class="mb-0 font-weight-bold small">Nome *</label>
              <input type="text" class="form-control" placeholder="nome completo" v-model="usuario.nome_cidadao" maxlength="100" required style="text-transform: capitalize">
            </div>
            <div class="form-group mb-2">
              <label class="mb-0 font-weight-bold small">Email *</label>
              <input type="email" autocomplete="email" class="form-control" placeholder="Seu endereço de email" v-model="usuario.email" maxlength="100" required>
            </div>
            <div class="form-group mb-2" v-show="false">
              <label class="mb-0 font-weight-bold small">Sexo</label>
              <select class="form-control" id="sexo" v-model="usuario.sexo">
                <option :value="null">Escolha uma opção</option>
                <option value="1">Masculino</option>
                <option value="2">Feminino</option>
                <option value="3">Outro</option>
              </select>
            </div>
          </div>
          <div class="form-group mb-2">
            <label class="mb-0 font-weight-bold small">Telefone com DDD *</label>
            <the-mask id="telefone" :mask="['(##) ####-####', '(##) #####-####']" class="form-control" type="tel" v-model="usuario.telefone" placeholder="(##) #####-####" required/>
          </div>
          <div v-if="!editarTelefone">
            <div class="form-group form-check mb-0 pt-3" v-show="editar">
              <input type="checkbox" class="form-check-input" id="editarSenha" v-model="trocarSenha" maxlength="100">
              <label class="form-check-label" for="editarSenha">Trocar senha</label>
            </div>
            <div class="form-group mb-2">
              <label class="mb-0 font-weight-bold small">Senha *</label>
              <input type="password" autocomplete="new-password" class="form-control" placeholder="Senha (mínimo 6 dígitos)" minlength="6" maxlength="100" v-model="senha" required :disabled="!trocarSenha && editar">
            </div>
            <div class="form-group mb-3">
              <label class="mb-0 font-weight-bold small">Confirmar senha *</label>
              <input type="password" autocomplete="new-password" class="form-control" placeholder="Confirme sua senha" minlength="6" maxlength="100" v-model=senhaConfirmacao required :disabled="!trocarSenha && editar">
            </div>
          </div>
          <div class="small">* Campos Obrigatórios</div>
          <div class="mt-3 text-muted">
            <p class="small m-0">
              Ao continuar você concorda com os
              <router-link to="/termos-uso" class="text-primary font-weight-bold">Termos de Uso</router-link>
              do app e confirma que leu a
              <router-link to="/privacidade" class="text-primary font-weight-bold">Política de Privavidade</router-link>
            </p>
          </div>

          <div class="btn-bottom fixed-bottom">
            <div class="container">
              <button class="btn btn-secondary btn-block py-2" :disabled="load">
                <span v-show="load"><i class="fa fa-spinner fa-pulse"></i> Aguarde</span>
                <span v-show="!load">{{editar ? 'Salvar' : 'Cadastrar'}}</span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>

    <vue-recaptcha ref="recaptcha" size="invisible" :sitekey="sitekey" @verify="editarOrCadastrarCidadao" @expired="onCaptchaExpired" />

  </div>
</template>

<script>
  import {TheMask} from 'vue-the-mask';
  import Loading from "../components/Loading";
  import TopBar from "../components/TopBar";
  import VueRecaptcha from 'vue-recaptcha';
  import LoginFacebook from "../components/auth/LoginFacebook";

  export default {
    name: 'Perfil',
    components: {LoginFacebook, TopBar, TheMask, Loading, VueRecaptcha},
    data() {
      return {
        sitekey: '6Lc53p0bAAAAABqSfxT6lsRDwWepUo92tNVCK-iT',
        token: localStorage.getItem('token'),
        load: false,

        senhaConfirmacao: '',
        editar: false,
        trocarSenha: false,
        senha: '',
        url: '',
        editarTelefone: false,

        usuario:  {
          nome_cidadao: '',
          telefone: '',
          email: '',
          sexo: null,
          avatar: ''
        },
      }
    },

    methods: {
      buscarUsuario(callback) {
        this.load = true;
        this.$http.get('app/cidadao/' + this.prefeituraToken + '/' + this.token)
          .then(response => {
            this.load = false;
            this.usuario = response.data;

            if (callback) {
              this.$store.state.user = response.data;
              callback();
            }

          }, res => {
            this.load = false;
            if (res.status === 401 && res.data.result !== '') {
              this.$store.commit('logout');
              this.$router.push(this.url + '/perfil');
              this.$swal(res.data.result + ' ' + res.data.msg);
            }
          });
      },

      validate() {
        this.load = true;

        if ((!this.editar || this.trocarSenha) && (this.senha !== this.senhaConfirmacao)) {
          this.$swal("As senhas não correspondem.");
          this.load = false;
          return;
        }

        if (this.usuario.telefone.length < 10) {
          this.$swal("Informe um telefone válido! É a partir dele que entraremos em contato se for necessário.");
          this.load = false;
          return;
        }

        if (!this.editar) {
          this.$refs.recaptcha.execute();

        } else {
          this.editarOrCadastrarCidadao(null);
        }
      },

      editarOrCadastrarCidadao(recaptcha) {
        this.usuario.recaptcha = recaptcha;
        this.load = true;
        let token = '';

        if (this.editar) {
          token = '/' + this.token;

          if (this.trocarSenha) {
            this.usuario.senha = this.senha;
          }

        } else {
          this.usuario.senha = this.senha;
        }

        this.$http.post('app/cidadao/' + this.prefeituraToken + token, this.usuario)
          .then(res => {
            this.load = false;

            if (!this.editar && res && res.data.success) {
              localStorage.setItem('token', res.data.token);
              this.token = res.data.token;
            }

            this.$swal(this.editar ? "Dados editados com sucesso!" : "Cadastro realizado com sucesso");

            this.buscarUsuario(() => {
              if (!this.editar) {
                this.setOneSignal(this.usuario)
              }

              if (!this.goSolicitacao()) {
                this.$router.push('/perfil');
              }
            });

          }, res => {
            console.log(res);
            this.onCaptchaExpired();
            let msg = res.data && res.data.msg ? res.data.msg : 'Erro temporário';

            if (!this.editar && res.data && res.data.tipo === 1) {
              this.$swal({
                text: msg,
                buttons: ["NÃO", "SIM"]
              }).then((r) => {
                if (r) {
                  this.$router.push(this.url + '/perfil/esqueci-senha')
                }
              });

            } else {
              this.$swal(msg);
            }
          });
      },

      onCaptchaExpired() {
        this.load = false;
        this.$refs.recaptcha.reset();
      },

      registrar(data) {
        this.$store.commit('setDataUser', data);
        this.setOneSignal(data.dados);

        if (!this.goSolicitacao()) {
          this.$router.push('/perfil');
        }
      },

      setOneSignal(user) {
        if (this.oneSignalIntegration) {
          OneSignal.push(() => {
            OneSignal.isPushNotificationsEnabled(isEnabled => {
              if (isEnabled) {
                OneSignal.setExternalUserId(user.id_cidadao);
              }
            });
          });
        }
      },

      goSolicitacao() {
        if (sessionStorage.getItem('solicitacao')) {
          const dados = JSON.parse(sessionStorage.getItem('solicitacao'));

          if (dados.id_servico) {
            const url = dados.tipo_solicitacao === '1' ? /solicitacao/ : '/ouvidoria/';
            this.$router.push(url + dados.id_servico);
            return true;
          }
        }

        return false;
      }
    },

    created() {
      document.body.classList.remove("modal-open");
    },

    mounted() {
      this.token = localStorage.getItem('token');
      window.scrollTo(0,0);
      this.editarTelefone = this.$route.name === 'PerfilDadosTelefone';

      if (this.token) {
        this.editar = true;
        this.buscarUsuario();

      } else {
        if (this.user.nome_cidadao) {
          this.usuario.nome_cidadao = this.user.nome_cidadao;
          this.usuario.avatar = this.user.avatar;
        }
      }
    },

    computed: {
      user() {
        return this.$store.state.user
      },

      empresa() {
        return this.$store.state.empresa
      },

      sacola() {
        return this.$store.state.sacola
      }
    },
  }
</script>

<style scoped>
  .content {
    padding-bottom: 102px;
  }
</style>
