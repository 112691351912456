<template>
  <div>
    <div class="content">
      <loading :fullscrean="true" v-if="load"/>
      <div class="container" style="padding-top: 40px" v-else>
        <div class="d-flex align-items-center mb-5">
          <router-link to="/home" class="text-decoration-none text-primary font-weight-bold">
            <img src="../assets/brasaostomasdeaquino.jpg" style="width: 90px" class="">
          </router-link>
          <div class="ml-1">
            <h2 class="font-weight-bold m-0">Olá<span v-if="user.nome_cidadao">, {{user.nome_cidadao | first}}</span>!</h2>
            <p class="mb-1">Seja bem-vindo</p>
            <p class="small m-0">{{prefeitura.nome_cidade}} - {{prefeitura.sigla_estado}}</p>
          </div>
        </div>
        <div class="row no-gutters justify-content-center">
          <div class="col-12 col-lg-4 mb-2 p-1">
            <router-link to="/informativos" class="card shadow-sm bg bg-2 text-decoration-none card-home">
              <div class="card-body">
                <h5 class="font-weight-bold">Informativos</h5>
                <img src="../assets/icones/news.png" alt="Ouvidoria">
              </div>
            </router-link>
          </div>
          <div class="col-6 col-lg-4 mb-2 p-1">
            <router-link to="/servicos" class="card shadow-sm bg bg-1 text-decoration-none card-home">
              <div class="card-body">
                <h5 class="font-weight-bold">Serviços</h5>
                <img src="../assets/icones/servicos.png" alt="Serviços">
              </div>
            </router-link>
          </div>
          <div class="col-6 col-lg-4 mb-2 p-1">
            <router-link to="/ouvidoria" class="card shadow-sm bg bg-2 text-decoration-none card-home">
              <div class="card-body">
                <h5 class="font-weight-bold">Ouvidoria</h5>
                <img src="../assets/icones/ouvidoria.png" alt="Ouvidoria">
              </div>
            </router-link>
          </div>
          <div class="col-6 col-lg-4 mb-2 p-1">
            <router-link to="/perfil" class="card shadow-sm bg bg-2 text-decoration-none card-home">
              <div class="card-body">
                <h5 class="font-weight-bold">Perfil</h5>
                <img :src="'https://lecard-cdn.nyc3.digitaloceanspaces.com/solicitae/app/avatar_'+ (user.avatar ? user.avatar : '1') +'.png'" alt="Perfil" style="right: 0">
              </div>
            </router-link>
          </div>
          <div class="col-6 col-lg-4 mb-2 p-1">
            <router-link to="/prefeitura" class="card shadow-sm bg bg-1 text-decoration-none card-home">
              <div class="card-body">
                <h5 class="font-weight-bold">Quem Somos</h5>
                <img src="../assets/icones/prefeitura.png" alt="Prefeitura" style="right: 0">
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <botton-bar/>
  </div>
</template>

<script>
  import CardInfo from "../components/CardInfo";
  import Icon from "../components/Icon";
  import Loading from "../components/Loading";
  import BottonBar from "../components/BottonBar";
  export default {
    name: 'Home',
    components: {BottonBar, Loading, Icon, CardInfo},
    data() {
      return {
        load: false
      }
    },

    computed: {
      user() {
        return this.$store.state.user
      },

      prefeitura() {
        return this.$store.state.dados.prefeitura
      }
    },

    filters: {
      first(data) {
        let arr = data.split(' ');
        if(arr.length) {
          return arr[0];
        }
        // return arr.slice(0, -1).join(' ')
      }
    }
  }
</script>
<style scoped>
  .content {
    background: url("../assets/wave-light.png");
    background-size: 100% 195px;
    background-repeat: no-repeat;
    background-position: top;
    min-height: calc(100vh - 152px);
  }

  .bg {
    height: 125px;
    color: #fff;
    border-radius: 12px;
  }

  .bg-1 {
    background-color: #1e336c!important;
  }

  .bg-2 {
    background-color: #f47c34!important;
  }

  .card-home {
    position: relative;
    overflow: hidden;
  }

  .card-home img {
    width: 96px;
    position: absolute;
    right: 10px;
    top: 40px;
  }

  .content .container {
    max-width: 720px;
  }
</style>
